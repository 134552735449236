var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "flex flex-wrap justify-between items-center pv3 link b--adori-gray bg-adori-gray br2 relative mb2",
    },
    [
      _c("a", {
        ref: "videoDownloadRef",
        staticClass: "dn",
        attrs: { download: "" },
      }),
      _vm.intent === "PUBLISH_TO_YOUTUBE"
        ? _c("div", { staticClass: "flex flex-column w-100" }, [
            _c(
              "div",
              { staticClass: "flex items-center justify-evenly w-100" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "w-10 flex dim pointer items-center ml4 relative img-div",
                    on: { click: _vm.imageClicked },
                  },
                  [
                    _c("img", {
                      staticClass: "br2",
                      attrs: {
                        src: _vm.audioPreviewImage,
                        alt: "",
                        width: "65",
                        height: "65",
                      },
                    }),
                    _c(
                      "i",
                      {
                        staticClass:
                          "material-icons cam light-gray pointer dim f6 image-icon absolute",
                      },
                      [_vm._v(" photo_camera ")]
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "flex flex-column justify-between w-20" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "f6 overflow-y-hidden w-90 truncate light-gray ttc",
                        attrs: { title: _vm.title },
                      },
                      [
                        _c("span", { staticClass: "gray" }, [_vm._v("Title:")]),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.title ? _vm.title : "Loading audio details..."
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "f6 overflow-y-hidden w-90 truncate light-gray mt3",
                      },
                      [
                        _c("span", { staticClass: "gray" }, [
                          _vm._v("Keywords:"),
                        ]),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.keywords ? _vm.keywords : " ") +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                ),
                _c("div", { staticClass: "flex flex-column w-20" }, [
                  _c(
                    "div",
                    { staticClass: "f6 overflow-y-hidden w-90 light-gray ml3" },
                    [
                      _c("span", { staticClass: "gray" }, [_vm._v("Size:")]),
                      _vm._v("\n          " + _vm._s(_vm.size) + "\n        "),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "f6 overflow-y-hidden w-90 truncate light-gray ml3 mt3",
                    },
                    [
                      _c("span", { staticClass: "gray" }, [
                        _vm._v("Duration:"),
                      ]),
                      _vm._v(
                        "\n          " + _vm._s(_vm.duration) + "\n        "
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "flex flex-column w-20" }, [
                  _c(
                    "div",
                    { staticClass: "f6 overflow-y-hidden w-90 light-gray ml3" },
                    [
                      _c("span", { staticClass: "gray" }, [_vm._v("Publish:")]),
                      _vm._v(
                        "\n          " + _vm._s(_vm.publish) + "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "f6 overflow-y-hidden w-90 truncate light-gray ml3 mt3",
                    },
                    [
                      _c("span", { staticClass: "gray" }, [
                        _vm._v("Include Visuals:"),
                      ]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.includeVisuals ? "Yes" : "No") +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "flex flex-column w-20 mr3" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "f6 overflow-y-hidden w-90 truncate light-gray ml3 ttc",
                    },
                    [
                      _c("span", { staticClass: "gray" }, [_vm._v("Privacy:")]),
                      _vm._v(
                        "\n          " + _vm._s(_vm.privacy) + "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "f6 overflow-y-hidden w-90 truncate light-gray ml3 mt3",
                    },
                    [
                      _c("span", { staticClass: "gray" }, [
                        _vm._v("Category:"),
                      ]),
                      _vm._v(
                        "\n          " + _vm._s(_vm.category) + "\n        "
                      ),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "flex justify-between items-center w-10" },
                  [
                    _vm.audio
                      ? _c(
                          "div",
                          {
                            staticClass: "flex justify-between pl4 pointer dim",
                            on: { click: _vm.editPublish },
                          },
                          [
                            _c(
                              "i",
                              { staticClass: "material-icons light-gray f4" },
                              [_vm._v("edit")]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
            _c("div", { staticClass: "subtitle mt2 flex f6 light-gray w-60" }, [
              _vm.isSubsEdited
                ? _c(
                    "div",
                    { staticClass: "flex items-center" },
                    [
                      _c("span", { staticClass: "gray mr2" }, [
                        _vm._v("Burn Subtitles into video:"),
                      ]),
                      _c("TheToggleButton", {
                        attrs: {
                          value: _vm.enableSubtitle,
                          "checked-bg": "#de1a23",
                          height: "16",
                          width: "30",
                        },
                        on: {
                          click: function ($event) {
                            _vm.enableSubtitle = !_vm.enableSubtitle
                          },
                        },
                      }),
                      _c("BaseButtonRed", {
                        staticClass: "ml3",
                        attrs: {
                          text: "Select Subtitle template",
                          onClick: _vm.chooseStyle,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ])
        : _vm.intent === "DOWNLOAD_VIDEO"
        ? _c("div", { staticClass: "flex flex-column w-100" }, [
            _c(
              "div",
              { staticClass: "flex items-center justify-evenly w-100" },
              [
                _c(
                  "div",
                  {
                    staticClass: "w-10 flex items-center ml4 relative img-div",
                  },
                  [
                    _c("img", {
                      staticClass: "br2",
                      attrs: {
                        src: _vm.audioPreviewImage,
                        alt: "",
                        width: "65",
                        height: "65",
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "flex flex-column justify-between w-30" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "f6 overflow-y-hidden w-90 truncate light-gray ttc",
                        attrs: { title: _vm.title },
                      },
                      [
                        _c("span", { staticClass: "gray" }, [_vm._v("Title:")]),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.title ? _vm.title : "Loading audio details..."
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                ),
                _c("div", { staticClass: "flex flex-column w-20" }, [
                  _c(
                    "div",
                    { staticClass: "f6 overflow-y-hidden w-90 light-gray ml3" },
                    [
                      _c("span", { staticClass: "gray" }, [_vm._v("Size:")]),
                      _vm._v("\n          " + _vm._s(_vm.size) + "\n        "),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "flex flex-column w-20" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "f6 overflow-y-hidden w-90 truncate light-gray ml3",
                    },
                    [
                      _c("span", { staticClass: "gray" }, [
                        _vm._v("Duration:"),
                      ]),
                      _vm._v(
                        "\n          " + _vm._s(_vm.duration) + "\n        "
                      ),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-wrap justify-center items-center w-20",
                  },
                  [
                    _vm.showProgressBar
                      ? _c(
                          "div",
                          { staticClass: "progress" },
                          [
                            _c("ProgressBar", {
                              attrs: {
                                options: _vm.options,
                                value: _vm.progressBarvalue,
                                max: 100,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm.audio
                      ? _c(
                          "div",
                          {
                            staticClass: "flex flex-wrap items-center dim",
                            class: _vm.uploadListStatus[_vm.guid]
                              ? "not-allowed"
                              : "pointer",
                            on: {
                              click: function ($event) {
                                _vm.uploadListStatus[_vm.guid]
                                  ? ""
                                  : _vm.downloadVideo()
                              },
                            },
                          },
                          [
                            _c("BaseButtonRed", {
                              staticClass: "youtube-link",
                              attrs: {
                                text: "Download",
                                icon: "download",
                                onClick: function () {},
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
            _c("div", { staticClass: "subtitle mt2 flex f6 light-gray w-60" }, [
              _vm.isSubsEdited
                ? _c(
                    "div",
                    { staticClass: "flex items-center" },
                    [
                      _c("span", { staticClass: "gray mr2" }, [
                        _vm._v("Burn Subtitles into video:"),
                      ]),
                      _c("TheToggleButton", {
                        attrs: {
                          value: _vm.enableSubtitle,
                          "checked-bg": "#de1a23",
                          height: "16",
                          width: "30",
                        },
                        on: {
                          click: function ($event) {
                            _vm.enableSubtitle = !_vm.enableSubtitle
                          },
                        },
                      }),
                      _c("BaseButtonRed", {
                        staticClass: "ml3",
                        attrs: {
                          text: "Select Subtitle template",
                          onClick: _vm.chooseStyle,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ])
        : _c("div", { staticClass: "tc w-100" }, [_vm._v("COMING SOON!!")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }