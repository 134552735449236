




import { useCreateAudioTrack, useCreateAudioUploadUrl, useGetAudioUploadStatus } from '@/hooks/audio'
import { useUploadImage } from '@/hooks/image'
import { computed, ref, SetupContext, watch } from '@vue/composition-api'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { uploadStatus } from '../Publish/publish'
import isEmpty from 'lodash.isempty'

@Component({
  setup(props: any, context: SetupContext) {
    context.root.$store.commit('setUploadListStatus', props.track.guid)
    const isManualUpload = props.track.isRss === false

    const uploadListIsEmpty = () => {
      if (isEmpty(context.root.$store.getters.uploadListStatus)) return false
      else {
        const status = Object.values(context.root.$store.getters.uploadListStatus)
        if (status.includes(true)) return true
        else return false
      }
    }

    const { mutate: uploadImage, data: imageResp } = useUploadImage()
    const { mutate: createAudioUploadUrl, data: uploadUrlResp } = useCreateAudioUploadUrl()
    const { mutate: createAudio } = useCreateAudioTrack((data: any) => {
      isManualUpload
        ? context.root.$store.commit('unsetUploadListStatus', props.track.guid)
        : context.root.$store.commit('unsetUploadListStatus', data.guid)

      context.root.$store.commit('setSelectedRssEpisodes', data)
      if (uploadListIsEmpty() === false) context.emit('removeEpisode')
      //   context.emit('removeEpisode', props.track.isRss === false ? props.track.guid : data.guid)
    })
    const uploadData: any = ref(uploadUrlResp)
    const imageData: any = ref(imageResp)
    const isUploadIdExist = computed(() => (isManualUpload ? true : !!uploadData.value?.uploadId))
    const uploadId = computed(() => (isManualUpload ? props.track.uploadId : uploadData.value?.uploadId))
    const interval: any = ref(false)

    !isManualUpload &&
      uploadImage({
        url: props.track.imageUrl ? props.track.imageUrl : context.root.$store.getters.currentPodcast.imageUrl,
      })
    !isManualUpload &&
      createAudioUploadUrl({
        url: props.track.audioUrl,
        category: 'TRACK',
      })

    useGetAudioUploadStatus(uploadId, { enabled: isUploadIdExist }, interval, (data: any) => {
      if ([uploadStatus.QUEUED, uploadStatus.STARTED].includes(data.importStatus)) {
        interval.value = 6000
      } else if (data.importStatus === uploadStatus.FINISHED || data.importStatus === null) {
        const payload: any = {
          category: 'TRACK',
          ytFeedUid: props.track.ytFeedUid,
          name: props.track.name,
          description: props.track.description,
          explicitContent: props.track.explicitContent,
          language: props.track.language,
          public: true,
        }
        if (isManualUpload) {
          payload['uploadId'] = props.track.uploadId
          payload['imageId'] = props.track.imageId
          props.track.keywords ? (payload['keywords'] = props.track.keywords) : ''
        } else {
          payload['uploadId'] = uploadId.value
          payload['guid'] = props.track.guid
          payload['imageId'] = imageData.value.id
        }
        interval.value = false
        createAudio(payload)
      } else if (data.importStatus === uploadStatus.FAILED) {
        interval.value = false
        context.root.$store.commit('unsetUploadListStatus', props.track.guid)
        context.root.$store.dispatch('pushNotification', {
          text: 'Oops! Processing failed. try again later',
          type: 'ERROR',
        })
      }
    })
  },
})
export default class AudioUpload extends Vue {
  @Prop() track!: any
  isUploadIdExist!: any
  created() {}
}
