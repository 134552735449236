


























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class StepperComponent extends Vue {
  @Prop(Number) step!: number
  @Prop(Array) stepsLabel!: any

  get stepperProgress() {
    return (100 / (this.stepsLabel.length - 1)) * (this.step - 1) + '%'
  }

  changeStep(step: number) {
    this.$emit('changeStep', step)
  }
}
