var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stepper" },
    [
      _c("div", { staticClass: "stepper-progress" }, [
        _c("div", {
          staticClass: "stepper-progress-bar",
          style: "width:" + _vm.stepperProgress,
        }),
      ]),
      _vm._l(_vm.stepsLabel, function (label, i) {
        return _c(
          "div",
          {
            key: i + 1,
            staticClass: "stepper-item",
            class: { current: _vm.step == i + 1, success: _vm.step > i + 1 },
          },
          [
            _c(
              "div",
              {
                staticClass: "stepper-item-counter pointer",
                on: {
                  click: function ($event) {
                    i + 1 < _vm.step ? _vm.changeStep(i + 1) : ""
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "icon-success",
                  attrs: {
                    src: require("@/assets/youtube/check-mark-white.png"),
                    alt: "",
                  },
                }),
                _c("span", { staticClass: "number" }, [
                  _vm._v("\n        " + _vm._s(i + 1) + "\n      "),
                ]),
              ]
            ),
            _c("div", { staticClass: "stepper-item-title" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.step > i + 1 ? label.onSuccess : label.initially) +
                  "\n    "
              ),
            ]),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }