



































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import get from 'lodash.get'

// common components
import ButtonAudioTranscript from '@/components/Common/Buttons/ButtonAudioTranscript.vue'
import ButtonAudioAdorify from '@/components/Common/Buttons/ButtonAudioAdorify.vue'

// utils
import { toReadableDate } from '@/utils/time'
import { secondsToHMS } from '@/components/Youtube/time'

@Component({ components: { ButtonAudioTranscript, ButtonAudioAdorify } })
export default class StepOneYoutubeOnboarding extends Vue {
  @Prop(String) audioUid!: string
  @Prop() episode!: any
  @Prop(Boolean) selectable!: boolean
  @Prop(String) selectionType!: string // One of 'SINGLE' or 'MULTI'

  @Getter selectedEpisodeList!: any
  @Getter isEpisodeSelectedForUpload!: any
  @Getter currentPodcast!: any

  @Action toggleSelectEpisodeForUpload!: any

  async created() {}

  get audio() {
    return this.episode
  }

  get audioPreviewImage() {
    return this.audio ? (this.audio.imageUrl ? this.audio.imageUrl : this.currentPodcast.imageUrl) : null
  }

  get audioDuration() {
    if (this.audio.durationSeconds) {
      return secondsToHMS(this.audio.durationSeconds)
    }
    return 'Calculating...'
  }

  get audioDate() {
    if (this.audio.publishedOn) {
      return toReadableDate(this.audio.publishedOn)
    }

    if (this.audio.publishTime) {
      return toReadableDate(this.audio.publishTime)
    }
    return toReadableDate(this.audio.createdOn)
  }

  get audioDescription() {
    return this.audio.description
  }

  get selectionIcon() {
    return this.isEpisodeSelectedForUpload(this.audio.guid) ? 'check_circle' : 'radio_button_unchecked'
  }

  get selectionStyle() {
    return this.isEpisodeSelectedForUpload(this.audio.guid) ? 'adori-red' : 'o-20'
  }

  truncate(str: string, count: number) {
    if (str.length < count) return str
    return str.substring(0, count) + '...'
  }

  handleAudioSelectionIconClicked() {
    if (this.selectable) {
      if (this.selectionType === 'SINGLE') {
        this.$store.dispatch('unselectAllEpisodeForUpload')
        this.toggleSelectEpisodeForUpload(this.audio)
        // this.$store.commit('setPublishList', this.selectedEpisodeList)
      }
      if (this.selectionType === 'MULTI' && this.selectedEpisodeList.length <= 9) {
        this.toggleSelectEpisodeForUpload(this.audio)
        // this.$store.commit('setPublishList', this.selectedEpisodeList)
      } else {
        // this.$store.commit('setPublishList', this.selectedEpisodeList)
        this.$store.commit('unselectEpisodesForUpload', this.audio)
      }
    }
  }
}
