var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      attrs: { hasCustomEvent: "" },
      on: {
        closeModal: function ($event) {
          return _vm.$emit("closeModal")
        },
      },
    },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "flex items-center" }, [
          _c("img", {
            staticClass: "mr2",
            attrs: { src: require("@/assets/youtube/error.svg") },
          }),
          _c("div", { staticClass: "f4 light-gray fw5 di" }, [
            _vm._v("String length too long"),
          ]),
        ]),
      ]),
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "flex flex-column" }, [
          _c("div", { staticClass: "f6 white" }, [
            _vm._v(
              "You have exceeded the maximum number of characters in this field"
            ),
          ]),
          _c(
            "div",
            { staticClass: "mv3" },
            _vm._l(_vm.episodeErrObj, function (ep, i) {
              return _c(
                "div",
                { key: i },
                [
                  _c("ErrorItem", {
                    ref: ep.guid,
                    refInFor: true,
                    attrs: { item: ep },
                    on: { "trigger-error-check": _vm.checkError },
                  }),
                ],
                1
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "flex justify-between" },
            [
              _c("BaseButtonRed", {
                attrs: {
                  text: "Trim and publish",
                  onClick: _vm.handleTrimPublish,
                },
              }),
              _c("BaseButtonRed", {
                attrs: {
                  text: "Publish",
                  onClick: _vm.handlePublish,
                  disabled: _vm.isPublishDisabled,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }