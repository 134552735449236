




































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import get from 'lodash.get'
import { useGetSubtitleTemplates, useGetSubtitles } from '@/hooks/subtitle'
import { useGetLanguages } from '@/hooks/transcript'
import SubtitlePreview from '@/components/Subtitle/SubtitlePreview.vue'

@Component({
  components: { SubtitlePreview },
  setup(props: any) {
    useGetSubtitleTemplates()
    useGetLanguages()
    const { isLoading: isLoadingSubtitles } = useGetSubtitles(props.trackUid)

    return {
      isLoadingSubtitles,
    }
  },
})
export default class ModalVideoPreview extends Vue {
  @Getter audiogramById!: any

  @Action getAudiogramById!: any

  @Prop(Number) currentTime!: number
  @Prop(String) trackUid!: string
  @Prop(String) backgroundVideo!: string
  @Prop(Boolean) isPlaying!: boolean
  @Prop(Array) tagsInTrack!: any[]
  @Prop(String) coverImage!: string
  @Prop(Array) audiogramPositions!: any[]

  audiogramImg = ''
  audiogramVideo = ''
  isLoadingSubtitles!: boolean
  isAudiogramLoading = false

  async mounted() {
    this.isAudiogramLoading = true
    const id = get(this.audiogramPositions, '[0].audiogramId', '')
    this.audiogramImg = get(this.audiogramById[id], 'image.originalUrl', '')
    this.audiogramVideo = get(this.audiogramById[id], 'video.originalUrl', '')
    if ((id && !this.audiogramImg) || (id && !this.audiogramVideo)) {
      const res = await this.getAudiogramById(id)
      this.audiogramImg = get(res, 'image.originalUrl', '')
      this.audiogramVideo = get(res, 'video.originalUrl', '')
    }
    this.isAudiogramLoading = false
  }

  togglePlay(playPause: boolean) {
    this.$emit('toggle-play', playPause)
  }
  scrollTimeline(value: number) {
    this.$emit('scroll-timeline', value)
  }
}
