




import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import { createTranscriptPoll, destroyTranscriptPoll } from '@/store/transcripts'

@Component
export default class ButtonAudioTranscript extends Vue {
  @Prop(String) audioUid!: string

  @Getter audio!: any
  @Getter getTranscript!: Function
  @Getter getSuggestions!: Function
  @Getter languages!: any

  @Action getAudio!: Function
  @Action showTranscript!: Function
  @Action generateTranscript!: Function
  @Action fetchTranscript!: Function
  @Action showConfirm!: Function
  @Action closeModal!: Function
  @Action getLanguages!: Function

  transcriptPollHandler: number | null = null
  transcriptPollInterval: number = 8000 // ms

  buttonDisabled: boolean = false

  selectedLanguage = 'en-US'

  async mounted() {
    if (!this.episode) {
      await this.getAudio(this.audioUid)
    }

    if (this.transcriptStatus === 'QUEUED' || this.transcriptStatus === 'STARTED') {
      this.buttonDisabled = true
      this.transcriptPollHandler = createTranscriptPoll(this.$store, this.audioUid, this.transcriptPollInterval)
    }
    if (this.audioStitchTaskStatus && this.audioStitchTaskStatus !== 'FINISHED') {
      this.buttonDisabled = true
    }
    if (this.transcriptStatus === 'FINISHED' && !this.transcript) {
      await this.fetchTranscript(this.audioUid)
    }
  }

  beforeDestroy() {
    if (this.transcriptPollHandler) {
      destroyTranscriptPoll(this.transcriptPollHandler)
    }
  }

  get episode() {
    return this.audio(this.audioUid)
  }

  get transcriptStatus() {
    return this.episode && this.episode.transcriptStatus
  }

  get audioStitchTaskStatus() {
    return this.episode && this.episode.audioStitchTaskStatus
  }

  @Watch('transcriptStatus')
  onTranscriptStatusChanged() {
    if (this.transcriptStatus === 'FINISHED' && this.transcriptPollHandler) {
      this.buttonDisabled = false
      destroyTranscriptPoll(this.transcriptPollHandler)
    }
  }

  @Watch('audioStitchTaskStatus')
  onAudioStitchTaskStatusChanged() {
    if (this.audioStitchTaskStatus && this.audioStitchTaskStatus !== 'FINISHED') {
      this.buttonDisabled = true
    } else {
      this.buttonDisabled = false
    }
  }

  get transcript() {
    return this.getTranscript(this.audioUid)
  }

  get buttonIcon() {
    if (!this.buttonDisabled) {
      return 'view_list'
    }
    return ''
  }

  get languageSelector() {
    const options: any = []

    this.languages.forEach((lang: any) => {
      options.push(`
        <option
          style="width: 150px;" class="f6" value="${lang.code}" key="${lang.code}">
          ${lang.name}
        </option>`)
    })

    return `
      <div class="mt3 scrollbar w5">
        <div class="flex justify-between">
          <div class="pb2 gray f6">Episode language 
          <BaseTooltip info='Language used in audio.' /> </div>
        </div>
        <div class=" scrollbar relative">
          <select
            id="langSelector"
            :disabled="languageDisabled"
            style="width: 250px;"
            class="input-reset light-gray bn br2 pa3 pv2 shadow-2 db arrow w-100 mb2 w2 f6  bg-adori-very-light-gray">
            ${options}
          </select>
          <i class="material-icons absolute light-gray" style="top:5px; right: 10px;">
            arrow_drop_down
          </i>
        </div>
      </div>`
  }

  get buttonText() {
    if (this.buttonDisabled) {
      return 'Generating transcript...'
    }

    if (this.transcriptStatus === 'FINISHED') {
      return 'View transcript'
    }

    return 'Generate transcript'
  }

  async buttonAction() {
    if (!this.transcriptStatus) {
      setTimeout(() => {
        const ele = document.getElementById('langSelector') as HTMLElement
        // @ts-ignore
        ele.value = this.selectedLanguage
        ele.addEventListener('change', (e: any) => {
          this.selectedLanguage = e.target.value
        })
      }, 500)
      if (!this.languages) await this.getLanguages()
      this.showConfirm({
        title: 'Generate Transcript for this episode',
        description: `<p>Transcription for this audio track will be made available, however this process can take some time depending on the length and quality of the audio ${this.languageSelector}`,
        yesButtonText: 'Confirm',
        noButtonText: 'Cancel',
        onConfirm: async () => {
          try {
            this.buttonDisabled = true
            this.closeModal()
            await this.generateTranscript({
              audioUid: this.audioUid,
              language: this.selectedLanguage || 'en-US',
            })
            this.transcriptPollHandler = createTranscriptPoll(this.$store, this.audioUid, this.transcriptPollInterval)
            await this.fetchTranscript(this.audioUid)
          } catch (error) {
            this.buttonDisabled = false
          }
        },
        onCancel: async () => {
          this.closeModal()
          this.buttonDisabled = false
        },
      })
    }
    if (this.transcriptStatus === 'FINISHED') {
      this.viewTranscript()
    }
  }

  viewTranscript() {
    this.showTranscript(this.audioUid)
  }
}
