var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseButtonBorder", {
    attrs: {
      icon: _vm.buttonIcon,
      text: _vm.buttonText,
      onClick: _vm.buttonAction,
      disabled: _vm.buttonDisabled,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }