

























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({ components: {} })
export default class ErrorItem extends Vue {
  @Prop() item!: any

  isActive: boolean = false
  title: string = ''
  description: string = ''

  mounted() {
    this.isActive = this.item.active
    this.title = this.item.title
    this.description = this.item.description
  }

  get titleCharacterRemaining() {
    return 99 - this.title.length
  }

  get descriptionCharacterRemaining() {
    return 4999 - this.description.length
  }

  get isTitleOverMax() {
    return this.titleCharacterRemaining < 0
  }
  get isDescriptionOverMax() {
    return this.descriptionCharacterRemaining < 0
  }

  get isTitleCharacterExceeded() {
    return this.item.isTitleCharacterExceeded
  }

  get isDescriptionCharacterExceeded() {
    return this.item.isDescriptionCharacterExceeded
  }
  toggle(event: any) {
    this.isActive = !this.isActive
  }

  startTransition(el: any) {
    el.style.height = el.scrollHeight + 'px'
  }

  endTransition(el: any) {
    el.style.height = ''
  }

  @Watch('isTitleOverMax')
  @Watch('isDescriptionOverMax')
  onErrorChanged() {
    this.$emit('trigger-error-check')
  }
}
