var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pv3 pointer darker-hover b--adori-gray br2 relative",
      on: { click: _vm.handleAudioSelectionIconClicked },
    },
    [
      _c("div", { staticClass: "flex justify-start w-100" }, [
        _c(
          "div",
          {
            staticClass: "flex items-center pl4 w-10",
            staticStyle: { cursor: "default" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
              },
            },
          },
          [
            _vm.selectable
              ? _c(
                  "i",
                  {
                    staticClass: "material-icons f4 white link pointer",
                    class: _vm.selectionStyle + " pointer",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleAudioSelectionIconClicked.apply(
                          null,
                          arguments
                        )
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.selectionIcon))]
                )
              : _vm._e(),
          ]
        ),
        _c("div", { staticClass: "w-10" }, [
          _c("img", {
            staticClass: "br2",
            attrs: {
              src: _vm.audioPreviewImage,
              alt: "",
              width: "40",
              height: "30",
            },
          }),
        ]),
        _c("div", { staticClass: "flex justify-start items-center w-80" }, [
          _c("div", { staticClass: "flex flex-column w-60" }, [
            _c(
              "div",
              {
                staticClass:
                  "f6 overflow-y-hidden w-100 truncate light-gray name-width",
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.audio ? _vm.audio.name : "Loading audio details..."
                    ) +
                    "\n        "
                ),
              ]
            ),
          ]),
          _vm.audio
            ? _c(
                "div",
                {
                  staticClass: "flex flex-wrap justify-left items-center w-40",
                },
                [
                  _c("div", { staticClass: "f7 light-gray ml3" }, [
                    _c("span", [_vm._v(_vm._s(_vm.audioDate))]),
                    _vm._v("\n          ·\n          "),
                    _c("span", [_vm._v(_vm._s(_vm.audioDuration))]),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }