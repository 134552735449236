


























import { Vue, Component, Prop } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import ErrorItem from '@/components/Youtube/ErrorItem.vue'

@Component({ components: { ErrorItem } })
export default class ModalErrorAlert extends Vue {
  @Prop() episodeErrObj: any

  @Action ytModalSetting!: any

  isPublishDisabled = true

  handleTrimPublish() {
    this.episodeErrObj.forEach((ep: any) => {
      this.ytModalSetting({
        guid: ep.guid,
        //@ts-ignore
        title: this.$refs[ep.guid][0].title.substring(0, 98),
        //@ts-ignore
        description: this.$refs[ep.guid][0].description.substring(0, 4998),
      })
    })
    this.$emit('final-publish')
    this.$emit('closeModal')
  }
  handlePublish() {
    this.episodeErrObj.forEach((ep: any) => {
      this.ytModalSetting({
        guid: ep.guid,
        //@ts-ignore
        title: this.$refs[ep.guid][0].title.substring(0, 99),
        //@ts-ignore
        description: this.$refs[ep.guid][0].description.substring(0, 4999),
      })
    })
    this.$emit('final-publish')
    this.$emit('closeModal')
  }
  checkError() {
    let noErrorFlag = true
    this.episodeErrObj.forEach((ep: any) => {
      if (
        //@ts-ignore
        this.$refs[ep.guid][0].isTitleOverMax ||
        //@ts-ignore
        this.$refs[ep.guid][0].isDescriptionOverMax
      ) {
        noErrorFlag = false
      }
    })
    if (noErrorFlag) {
      this.isPublishDisabled = false
    } else {
      this.isPublishDisabled = true
    }
  }
}
