var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.audioEditor
      ? _c(
          "div",
          [
            _c("TheHeader"),
            _c("div", { staticClass: "flex flex-wrap" }, [
              _c("div", { staticClass: "custom-limiter scrollbar mt4" }, [
                _vm.showHomeLink
                  ? _c(
                      "div",
                      {
                        staticClass: "flex light-gray pointer w3 dim",
                        on: { click: _vm.goToHome },
                      },
                      [
                        _vm.isYoutube
                          ? _c("div", { staticClass: "flex" }, [
                              _c("i", { staticClass: "material-icons v-mid" }, [
                                _vm._v(" home "),
                              ]),
                              _c("div", { staticClass: "ml1 mt-auto" }, [
                                _vm._v("Home"),
                              ]),
                            ])
                          : _c("div", { staticClass: "flex items-center" }, [
                              _c("i", { staticClass: "material-icons v-mid" }, [
                                _vm._v(" library_music "),
                              ]),
                              _c("div", { staticClass: "ml1" }, [
                                _vm._v("Audio"),
                              ]),
                            ]),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "relative w-100 flex justify-center" },
                  [
                    _c("StepperComponent", {
                      attrs: {
                        step: _vm.isBlogNetwork
                          ? _vm.youtubeStep - 1
                          : _vm.youtubeStep,
                        stepsLabel: _vm.stepsLabel,
                      },
                      on: { changeStep: _vm.changeStep },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "w-100 flex mt3" },
                  [
                    _c("BaseButtonBorder", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.youtubeStep !== 1,
                          expression: "youtubeStep !== 1",
                        },
                      ],
                      attrs: {
                        disabled: _vm.handlePrevious,
                        text: "Previous",
                        onClick: _vm.previousStep,
                      },
                    }),
                    _vm.youtubeStep !== 3
                      ? _c("BaseButtonRed", {
                          staticClass: "next",
                          attrs: {
                            loading: _vm.nextStepDisabled,
                            disabled: _vm.handleNext,
                            text: "Next",
                            onClick: _vm.nextStep,
                          },
                        })
                      : _vm._e(),
                    _vm.youtubeStep === 3 && _vm.intent === "PUBLISH_TO_YOUTUBE"
                      ? _c("BaseButtonRed", {
                          staticClass: "next",
                          attrs: {
                            loading: _vm.youtubeLoader,
                            disabled: _vm.disablePublish || _vm.youtubeLoader,
                            text: _vm.PublishText,
                            onClick: _vm.handleBeforePublish,
                          },
                        })
                      : _vm._e(),
                    _vm.youtubeStep === 3 && _vm.intent === "DOWNLOAD_VIDEO"
                      ? _c("BaseButtonRed", {
                          staticClass: "next",
                          attrs: {
                            loading: _vm.youtubeLoader,
                            disabled: _vm.disablePublish || _vm.youtubeLoader,
                            text: "Go to Home",
                            onClick: _vm.goToHome,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.youtubeStep === 1
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "bg-adori-light-gray br2 light-gray ba b--adori-gray pv4 mt4",
                      },
                      [
                        _c("div", { staticClass: "f3 pl4 mb3" }, [
                          _vm._v("Select Episodes to upload"),
                        ]),
                        _vm.isPublicFeedLoading
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "mh3 flex justify-center items-center w-100 mv3 episode-list scrollbar",
                              },
                              [_c("div", { staticClass: "bar-loader" })]
                            )
                          : _c(
                              "div",
                              { staticClass: "episode-list scrollbar" },
                              [
                                _vm.episodes.length === 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "w-100 flex flex-column items-center",
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/no-episodes.svg"),
                                            alt: "",
                                            height: "140",
                                          },
                                        }),
                                        _c("BaseButtonRed", {
                                          staticClass: "mt4 podcast-add",
                                          attrs: {
                                            text: "Add a Podcast",
                                            onClick: _vm.handleSearch,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._l(_vm.episodes, function (audio) {
                                  return _c("StepOneYoutubeOnboarding", {
                                    key: audio.guid,
                                    attrs: {
                                      audioUid: audio.guid,
                                      episode: audio,
                                      selectable: true,
                                      selectionType: "MULTI",
                                    },
                                  })
                                }),
                              ],
                              2
                            ),
                      ]
                    )
                  : _vm._e(),
                _vm.youtubeStep === 2
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "bg-adori-light-gray br2 light-gray ba b--adori-gray pa4 mt4",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "episode-list scrollbar" },
                          _vm._l(
                            _vm.selectedEpisodeList,
                            function (audio, index) {
                              return _c("StepTwoYoutubeOnboarding", {
                                key: audio.guid,
                                attrs: {
                                  index: index,
                                  guid: audio.guid,
                                  episode: audio,
                                },
                              })
                            }
                          ),
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.youtubeStep === 3
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "bg-adori-light-gray br2 light-gray ba b--adori-gray pa4 mt4",
                      },
                      [
                        _c("div", { staticClass: "mr3 ml1 mb2" }, [
                          _vm._v("Publish to or Download"),
                        ]),
                        _c("div", { staticClass: "flex items-center" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "ba bw1 br4 b--transparent bg-black-20 flex items-center",
                            },
                            [
                              _vm._l(_vm.platforms, function (tab) {
                                return _c(
                                  "label",
                                  {
                                    key: tab.id,
                                    staticClass:
                                      "f6 pv1 ph2 silver link pointer mr1 flex items-center",
                                    class:
                                      _vm.intent === tab.tabName
                                        ? "ba br4 b--transparent bg-black-80 light-gray"
                                        : "",
                                    on: {
                                      click: function ($event) {
                                        _vm.intent = tab.tabName
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: tab.src,
                                        alt: "",
                                        width: "20",
                                      },
                                    }),
                                    _c("span", { staticClass: "ml2" }, [
                                      _vm._v(_vm._s(tab.name)),
                                    ]),
                                  ]
                                )
                              }),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "f6 pv1 ph2 silver link pointer mr1 flex items-center",
                                  class:
                                    _vm.intent === "DOWNLOAD_VIDEO"
                                      ? "ba br4 b--transparent bg-black-80 light-gray"
                                      : "",
                                  on: {
                                    click: function ($event) {
                                      _vm.intent = "DOWNLOAD_VIDEO"
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "i",
                                    {
                                      staticClass:
                                        "material-icons adori-red f4 v-mid pa1",
                                    },
                                    [_vm._v(" download ")]
                                  ),
                                  _c("span", { staticClass: "ml2" }, [
                                    _vm._v("Download"),
                                  ]),
                                ]
                              ),
                            ],
                            2
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "mt3 episode-list scrollbar" },
                          _vm._l(_vm.selectedEpisodeList, function (audio) {
                            return _c("StepThreeYoutubeOnboarding", {
                              key: audio.guid,
                              attrs: {
                                guid: audio.guid,
                                episode: audio,
                                intent: _vm.intent,
                              },
                              on: { removeOnload: _vm.removeOnload },
                            })
                          }),
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "w-100 flex mt3" },
                  [
                    _c("BaseButtonBorder", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.youtubeStep !== 1,
                          expression: "youtubeStep !== 1",
                        },
                      ],
                      attrs: {
                        disabled: _vm.handlePrevious,
                        text: "Previous",
                        onClick: _vm.previousStep,
                      },
                    }),
                    _vm.youtubeStep !== 3
                      ? _c("BaseButtonRed", {
                          staticClass: "next",
                          attrs: {
                            loading: _vm.nextStepDisabled,
                            disabled: _vm.handleNext,
                            text: "Next",
                            onClick: _vm.nextStep,
                          },
                        })
                      : _vm._e(),
                    _vm.youtubeStep === 3 && _vm.intent === "PUBLISH_TO_YOUTUBE"
                      ? _c("BaseButtonRed", {
                          staticClass: "next",
                          attrs: {
                            loading: _vm.youtubeLoader,
                            disabled: _vm.disablePublish || _vm.youtubeLoader,
                            text: _vm.PublishText,
                            onClick: _vm.handleBeforePublish,
                          },
                        })
                      : _vm._e(),
                    _vm.youtubeStep === 3 && _vm.intent === "DOWNLOAD_VIDEO"
                      ? _c("BaseButtonRed", {
                          staticClass: "next",
                          attrs: {
                            loading: _vm.youtubeLoader,
                            disabled: _vm.disablePublish || _vm.youtubeLoader,
                            text: "Go to Home",
                            onClick: _vm.goToHome,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      [
        _vm.uploadListIsEmpty ? _c("ModalUploadStatus") : _vm._e(),
        _vm.modalAlertStatus
          ? _c("ModalErrorAlert", {
              attrs: { episodeErrObj: _vm.episodeErrObj },
              on: {
                closeModal: function ($event) {
                  _vm.modalAlertStatus = !_vm.modalAlertStatus
                },
                "final-publish": _vm.handlePublish,
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm.audioEditor ? _c("div", [_c("ViewAudioEditor")], 1) : _vm._e(),
    [2, 3].includes(_vm.youtubeStep)
      ? _c(
          "div",
          _vm._l(_vm.unUploadedTracks, function (track, index) {
            return _c("AudioUpload", {
              key: index,
              attrs: { track: track },
              on: { removeEpisode: _vm.removeEpisode },
            })
          }),
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }