var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "fixed flex justify-center items-center bg-adori-light-gray custom-css no-select shadow-5 custom-index br3",
    },
    [
      _c("div", { staticClass: "flex items-center white" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/youtube/mini_loader.gif"),
            alt: "",
            height: "20",
            width: "20",
          },
        }),
        _c("div", { staticClass: "ml3" }, [
          _vm._v(
            "Processing Audio " +
              _vm._s(_vm.perUpload + " / " + _vm.totalUploads) +
              ".."
          ),
        ]),
        _c("div", { staticClass: "ml3 loader loader-inline" }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }