












import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Component
export default class ModalUploadStatus extends Vue {
  @Getter uploadListStatus!: any

  get totalUploads() {
    return Object.values(this.uploadListStatus).length
  }

  get perUpload() {
    const uploads = Object.values(this.uploadListStatus)
    const perUpload = uploads.filter((element: any) => element === false).length
    return this.totalUploads - perUpload
  }
}
