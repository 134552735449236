var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "is-active": _vm.isActive } },
    [
      _c("div", { staticClass: "item-head" }, [
        _c(
          "button",
          {
            staticClass: "item-head-button pointer",
            on: { click: _vm.toggle },
          },
          [
            _c(
              "div",
              { staticClass: "overflow-y-hidden mw300 truncate white" },
              [_vm._v("Episode: " + _vm._s(_vm.title))]
            ),
            _c("span", { staticClass: "button-icon" }),
          ]
        ),
      ]),
      _c(
        "transition",
        {
          attrs: { name: "accordion-item" },
          on: {
            enter: _vm.startTransition,
            "after-enter": _vm.endTransition,
            "before-leave": _vm.startTransition,
            "after-leave": _vm.endTransition,
          },
        },
        [
          _vm.isActive
            ? _c("dd", { staticClass: "item-details" }, [
                _c("div", { staticClass: "item-details-inner" }, [
                  _vm.isTitleCharacterExceeded
                    ? _c("div", { staticClass: "title" }, [
                        _c(
                          "fieldset",
                          {
                            staticClass: "br2",
                            class: _vm.isTitleOverMax
                              ? "b--adori-red"
                              : "b--adori-light-gray",
                          },
                          [
                            _c("legend", { staticClass: "f6 white" }, [
                              _vm._v("Title"),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.title,
                                  expression: "title",
                                },
                              ],
                              staticClass:
                                "gray-bg bn br2 gray f6 mb2 white w-100",
                              attrs: {
                                type: "text",
                                name: "title",
                                placeholder: "Episode Title",
                              },
                              domProps: { value: _vm.title },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.title = $event.target.value
                                },
                              },
                            }),
                          ]
                        ),
                        _c("div", { staticClass: "mb3 title-count f6" }, [
                          _c(
                            "span",
                            { class: { overMax: _vm.isTitleOverMax } },
                            [_vm._v(_vm._s(_vm.titleCharacterRemaining))]
                          ),
                          _c("span", [_vm._v("/ 99")]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.isDescriptionCharacterExceeded
                    ? _c("div", { staticClass: "description" }, [
                        _c(
                          "fieldset",
                          {
                            staticClass: "br2",
                            class: _vm.isDescriptionOverMax
                              ? "b--adori-red"
                              : "b--adori-light-gray",
                          },
                          [
                            _c("legend", { staticClass: "f6 white" }, [
                              _vm._v("Description"),
                            ]),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.description,
                                  expression: "description",
                                },
                              ],
                              staticClass:
                                "gray-bg bn br2 gray f6 mb2 white w-100 scrollbar overflow-y-auto",
                              attrs: {
                                type: "text",
                                rows: "8",
                                name: "description",
                                placeholder: "Episode Description",
                              },
                              domProps: { value: _vm.description },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.description = $event.target.value
                                },
                              },
                            }),
                          ]
                        ),
                        _c("div", { staticClass: "the-count f6" }, [
                          _c(
                            "span",
                            { class: { overMax: _vm.isDescriptionOverMax } },
                            [_vm._v(_vm._s(_vm.descriptionCharacterRemaining))]
                          ),
                          _c("span", [_vm._v("/ 4999")]),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }