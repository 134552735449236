

























































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VueSlider from 'vue-slider-component'
import { Action, Getter } from 'vuex-class'
import { secondsToHMS } from '@/components/Youtube/time'
import { returnHHMMSS } from '@/utils/time'
import get from 'lodash.get'
import { computed, SetupContext } from '@vue/composition-api'
import { useGetTrackTags } from '@/hooks/tag'
import { useGetTrackAudiograms } from '@/hooks/audiogram'
import ModalVideoPreview from '@/components/Youtube/Modal/ModalVideoPreview.vue'

@Component({
  components: { VueSlider, ModalVideoPreview },
  setup(props: any, { root }: SetupContext) {
    const trackUid = computed(() =>
      get(
        root.$store.getters.selectedRssEpisodes.filter((obj: any) => obj.guid === props.guid),
        '[0].uid',
        false
      )
    )
    const isTrackEnabled = computed(() => !!trackUid.value)
    useGetTrackTags(trackUid, { enabled: isTrackEnabled })
    useGetTrackAudiograms(trackUid, {
      enabled: isTrackEnabled,
    })
    return {
      trackUid,
    }
  },
})
export default class StepTwoYoutubeOnboarding extends Vue {
  @Getter networkId!: any
  @Getter uploadListStatus!: any
  @Getter selectedEpisodeSettings!: any
  @Getter selectedRssEpisodes!: any
  @Getter getRssEpisodeByGuid!: any
  @Getter tagPositions!: any
  @Getter callTags!: any
  @Getter currentPodcast!: any
  @Getter youtubeTourShown!: string[]
  @Getter networkType!: any

  @Action checkAudioUpload!: any
  @Action ytModalSetting!: any
  @Action ytModalId!: Function
  @Action getAudioTags!: any
  @Action getAudioAudiogram!: any
  @Action uploadTourStatus!: any

  @Prop() episode!: any
  @Prop() guid!: string
  @Prop() index!: number

  isPreview = false
  trackUid!: string

  $shepherd: any
  title: string = ''
  description: string = ''
  keywords: any = []
  categoryId!: number
  privacy: string = ''
  publish: string = ''
  thumbnailSrc: string = ''
  width: number = 1280
  height: number = 720
  beginTime: string = ''
  endTime: string = ''
  isPlaying: boolean = false
  currentTime = 0
  currentAudio!: any
  uploadStatus = false
  youtubeAuthHandle: any = null

  options: any = {
    eventType: 'auto',
    width: 'auto',
    height: 6,
    dotSize: 16,
    dotHeight: null,
    dotWidth: null,
    min: 0,
    interval: 1,
    show: true,
    speed: 0.5,
    disabled: false,
    piecewise: false,
    useKeyboard: false,
    enableCross: true,
    piecewiseLabel: false,
    tooltip: 'none',
    tooltipDir: 'top',
    reverse: false,
    clickable: true,
    realTime: false,
    lazy: false,
    formatter: null,
    bgStyle: null,
    sliderStyle: null,
    processStyle: {
      background: '#DE1A23',
    },
    piecewiseActiveStyle: null,
    tooltipStyle: null,
    labelStyle: null,
    labelActiveStyle: null,
  }

  get isSecondIndex() {
    return this.index == 1 ? true : false
  }

  get datePickerStyle() {
    return this.isSecondIndex ? { top: '100%', left: 0 } : {}
  }

  get isFreeAccount() {
    return ['VIDEO_FREE', 'VIDEO_BASIC'].includes(this.networkType)
  }

  get videoResolutionMenu() {
    let menu = [
      {
        name: 'HD (1280 x 720)',
        icon: 'stay_primary_landscape',
        onClick: () => {
          this.width = 1280
          this.height = 720
        },
      },
      {
        name: 'HD (720 x 1280)',
        icon: 'stay_primary_portrait',
        onClick: () => {
          this.width = 720
          this.height = 1280
        },
      },
      {
        name: 'HD (720 x 720)',
        icon: 'crop_square',
        onClick: () => {
          this.width = 720
          this.height = 720
        },
      },
    ]
    if (!this.isFreeAccount) {
      menu = [
        {
          name: 'Full HD (1920 x 1080)',
          icon: 'stay_primary_landscape',
          onClick: () => {
            this.width = 1920
            this.height = 1080
          },
        },
        {
          name: 'Full HD (1080 x 1920)',
          icon: 'stay_primary_portrait',
          onClick: () => {
            this.width = 1080
            this.height = 1920
          },
        },
        {
          name: 'Full HD (1080 x 1080)',
          icon: 'crop_square',
          onClick: () => {
            this.width = 1080
            this.height = 1080
          },
        },
        ...menu,
      ]
    }
    return menu
  }

  get sliderValue() {
    return this.currentTime
  }
  set sliderValue(value: number) {
    this.currentTime = value
  }
  get audioInSecs() {
    return this.getRssEpisodeByGuid(this.guid)?.durationMillis / 1000 || this.audio.durationSeconds
  }

  get sliderValueinHHMMSS() {
    return new Date(this.sliderValue * 1000).toISOString().substr(11, 8)
  }

  get active() {
    let ratio = (this.width / this.height).toFixed(4)
    return ratio == '1.7778' ? 'landscape' : ratio === '0.5625' ? 'portrait' : ratio === '1.0000' ? 'square' : 'custom'
  }

  setActive(size: string) {
    if (size === 'square') {
      this.width = 720
      this.height = 720
    } else if (size === 'landscape') {
      this.width = 1280
      this.height = 720
    } else {
      this.width = 720
      this.height = 1280
    }
  }

  get playButtonIcon() {
    return (this.isPlaying ? 'pause' : 'play') + '_circle_outline'
  }

  get audio() {
    return this.episode
  }

  get audioPreviewImage() {
    return this.audio ? (this.audio.imageUrl ? this.audio.imageUrl : this.currentPodcast.imageUrl) : null
  }

  get imagePreview() {
    return this.trackUid ? get(this.$store.getters.audio(this.trackUid), 'imageUrl', '') : ''
  }

  get backgroundVideo() {
    return get(this.audio, 'videoUrl', '')
  }

  get audiogramPositions() {
    const audiogramPositions = this.$store.getters.audiogramPositions(this.trackUid) || []
    return audiogramPositions
  }

  get tagStamps() {
    const tagPositions = this.$store.getters.tagPositions(this.trackUid) || []
    return tagPositions
  }

  onCurrentTime(currentTime: number) {
    if (Math.abs(currentTime - this.currentTime) > 0.5) {
      this.currentAudio.currentTime = currentTime
    }
  }

  hmsToSeconds(t: string) {
    const [hours, minutes, seconds] = t.split(':')
    return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds)
  }

  dragend(index: any) {
    this.currentAudio.currentTime = index.currentValue
  }
  callback(value: any) {
    this.currentAudio.currentTime = value
  }

  seekBackward() {
    this.currentAudio.currentTime -= 10
  }
  seekForward() {
    this.currentAudio.currentTime += 10
  }

  togglePlay() {
    if (this.currentAudio.paused) {
      this.currentAudio.play()
      this.isPlaying = true
      this.currentAudio.addEventListener(
        'timeupdate',
        () => (this.currentTime = Math.round(this.currentAudio.currentTime))
      )
      this.currentAudio.addEventListener('ended', () => {
        this.isPlaying = false
      })
    } else {
      this.currentAudio.pause()
      this.isPlaying = false
      this.currentAudio.removeEventListener('timeupdate')
      this.currentAudio.removeEventListener('ended')
    }
  }

  get clipEnd() {
    return this.endTime
  }

  get clipStart() {
    return this.beginTime
  }

  get duration() {
    let hms = secondsToHMS(this.hmsToSeconds(this.clipEnd) - this.hmsToSeconds(this.clipStart))
    return hms[0] === '-' ? 'Invalid' : hms ? hms : '0s'
  }

  get audioSrc() {
    return this.episode.audioUrl
  }

  get sliderEndTime() {
    return new Date(this.getRssEpisodeByGuid(this.guid)?.durationMillis || this.audio.durationSeconds * 1000)
      .toISOString()
      .substr(11, 8)
  }

  get startTimeSec() {
    return this.convertHHMMSStoSecs(this.beginTime)
  }

  get endTimeSec() {
    return this.convertHHMMSStoSecs(this.endTime)
  }

  //   get tagLength() {
  //     const trackUid =
  //       this.episode.isRss === false && this.selectedRssEpisodes.length === 1
  //         ? get(this.selectedRssEpisodes, '[0].uid', false)
  //         : get(this.getRssEpisodeByGuid(this.guid), 'uid', false)

  //     let tags = trackUid ? this.tagPositions(trackUid) || [] : []
  //     if (tags.length) {
  //       tags = tags.filter((obj: any) => {
  //         const offsetMillis = obj.offsetMillis / 1000
  //         if (offsetMillis >= this.startTimeSec && offsetMillis <= this.endTimeSec) return obj
  //       })
  //     }
  //     return tags.length
  //   }

  //   get audiogramLength() {
  //     const trackUid =
  //       this.episode.isRss === false && this.selectedRssEpisodes.length === 1
  //         ? get(this.selectedRssEpisodes, '[0].uid', false)
  //         : get(this.getRssEpisodeByGuid(this.guid), 'uid', false)

  //     return trackUid ? get(this.$store.getters.audiogramPositions(trackUid), 'length', 0) : 0
  //   }

  //   get totalVisualElements() {
  //     return this.tagLength + this.audiogramLength
  //   }

  convertHHMMSStoSecs(duration: string) {
    const [hours, minutes, seconds] = duration.split(':')

    return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds)
  }

  formatSizeDuration(e: any, input: string) {
    let elementRef, element, maxlength
    if (input === 'begin') {
      elementRef = this.$refs.beginRef
      element = this.beginTime
      maxlength = 8
    } else if (input === 'end') {
      elementRef = this.$refs.endRef
      element = this.endTime
      maxlength = 8
    } else if (input === 'width') {
      elementRef = this.$refs
      element = this.width.toString()
      maxlength = 4
    } else {
      elementRef = this.$refs
      element = this.height.toString()
      maxlength = 4
    }
    if (
      element.length < maxlength &&
      (e.keyCode == 8 || // backspace
        e.keyCode == 46 || // delete
        (e.keyCode >= 35 && e.keyCode <= 40) || // arrow keys/home/end
        (e.keyCode >= 48 && e.keyCode <= 57) || // numbers on keyboard
        (e.keyCode >= 96 && e.keyCode <= 105) || // number on keypad
        e.keyCode == 9) //Tab for keyboard accessibility
    ) {
      if (input != 'width' && input != 'height' && e.keyCode != 8 && (element.length === 2 || element.length === 5)) {
        let colon = (element += ':')
        // @ts-ignore
        Vue.set(elementRef, 'value', colon)
      }
    } else {
      if (
        element.length == maxlength &&
        (e.keyCode == 8 || e.keyCode == 9 || e.keyCode == 46 || (e.keyCode >= 35 && e.keyCode <= 40))
      ) {
      } else {
        e.preventDefault() // Prevent character input
      }
    }
  }

  truncate(str: string, count: number) {
    if (str.length < count) return str
    return str.substring(0, count) + '...'
  }

  get timeSelectOptions() {
    let audioTimeObj: any = returnHHMMSS(new Date(this.episode.durationSeconds * 1000).toISOString().substr(11, 8))

    const hours = audioTimeObj.hours === 0 ? [0] : Array.from(Array(audioTimeObj.hours + 1).keys())

    const minutes =
      audioTimeObj.minutes === 0
        ? [0]
        : audioTimeObj.hours > 0
        ? Array.from(Array(60).keys())
        : Array.from(Array(audioTimeObj.minutes + 1).keys())
    const seconds =
      audioTimeObj.minutes > 0 ? Array.from(Array(60).keys()) : Array.from(Array(audioTimeObj.seconds).keys())

    return {
      hours,
      minutes,
      seconds,
    }
  }

  htmlDecode(input: string) {
    try {
      const blockElements = ['p', 'div'] // add any additional block elements here
      const listElements = ['ul', 'ol'] // add any additional list elements here
      let tagRegex = /<(\/?)\s*(\w+)[^>]*>/g
      let plainText = input

      // Extract links and add it on side inside brackets
      let linkText = ''
      let linkHref = ''
      plainText = plainText.replace(/(<a[^>]*>)(.*?)(<\/a>)/gi, (match, startTag, innerText, endTag) => {
        linkText = innerText.trim()
        linkHref = startTag.match(/href="(.*?)"/i)[1].trim()
        return `${linkText} [${linkHref}]`
      })

      // Remove HTML tags and replace them with appropriate plain text characters
      plainText = plainText.replace(tagRegex, (match, p1, p2) => {
        if (p1 === '/') {
          return ''
        }
        const plainTag = blockElements.includes(p2) ? `\n\n` : ''
        if (listElements.includes(p2)) {
          return plainTag + '\n'
        } else if (p2 === 'li' && p1 !== '/') {
          return '\n-' + plainTag
        } else {
          return plainTag
        }
      })

      // Remove excess whitespace and trim the resulting plain text string
      plainText = plainText.trim()

      // Convert entities to their plain text equivalents
      let doc = new DOMParser().parseFromString(plainText, 'text/html')

      return doc.documentElement.textContent
    } catch (e) {
      let doc = new DOMParser().parseFromString(input, 'text/html')

      return doc.documentElement.textContent
    }
  }

  mounted() {
    if (this.index === 0) {
      this.$nextTick(() => {
        const tour = this.$shepherd({
          defaultStepOptions: {
            classes: 'shepherd-theme-custom',
          },
          popperOptions: {
            modifiers: [{ name: 'offset', options: { offset: [0, 12] } }],
          },
          useModalOverlay: true,
        })
        tour.addSteps([
          {
            title: 'Audio player<span>1/4</span>',
            text: `Use slider or seek button to change progress of the audio<video muted autoplay loop ><source  src="https://storage.googleapis.com/dev_audio_adorilabs/samples/tour/player.mp4" type="video/mp4" ></video>`,
            content: 'ff',
            classes: 'test',
            attachTo: {
              element: '#edit_audio_tour_step_1',
              on: 'right',
            },
            when: {
              show() {},
            },
            buttons: [
              {
                text: 'Exit tour',
                action: () => {
                  tour.cancel()
                  this.uploadTourStatus({
                    tourName: 'edit_audio_tour',
                    isLoggedIn: this.networkId,
                  })
                },
                classes: 'shepherd-back',
              },
              {
                text: 'Next',
                action: () => {
                  tour.next()
                },
                classes: 'shepherd-next',
              },
            ],
            id: 'one',
          },
          {
            title: 'Audio clipper<span>2/4</span>',
            text: `Use slider to seek and pointer to select time or use dropdown for clipping<video muted autoplay loop > <source  src="https://storage.googleapis.com/dev_audio_adorilabs/samples/tour/clipper.mp4" type="video/mp4" ></video>`,
            attachTo: {
              element: '#edit_audio_tour_step_2',
              on: 'right',
            },
            when: {},
            buttons: [
              {
                text: 'Back',
                action: () => {
                  tour.back()
                },
                classes: 'shepherd-back',
              },
              {
                text: 'Next',
                action: () => {
                  tour.next()
                },

                classes: 'shepherd-next',
              },
            ],
            id: 'two',
          },
          {
            title: 'Choose Aspect Ratio<span>3/4</span>',
            text: `<span>Select recommended size from dropdown or input a custom value.</span><span><strong>LANDSCAPE</strong> - Best for Youtube and website</span><span> <strong>SQUARE</strong>- Best for Twitter and Instagram</span><span> <strong>PORTRAIT</strong>- Best for Shorts and Reels</span>`,
            attachTo: {
              element: '#edit_audio_tour_step_3',
              on: 'right',
            },
            when: {},
            buttons: [
              {
                text: 'Back',
                action: () => {
                  tour.back()
                },
                classes: 'shepherd-back',
              },
              {
                text: 'Next',
                action: () => {
                  tour.next()
                },

                classes: 'shepherd-next',
              },
            ],
            id: 'three',
          },
          {
            title: 'Enhance Audio<span>4/4</span>',
            text: `Wait until processing is done and then click on <strong>Advanced Editor</strong> to add visual tags, edit subtitles.`,
            attachTo: {
              element: '#edit_audio_tour_step_4',
              on: 'left',
            },
            when: {
              show() {},
              destroy: function () {},
            },

            buttons: [
              {
                text: 'Back',
                action: tour.back,
                classes: 'shepherd-back',
              },
              {
                text: 'Done',
                action: () => {
                  tour.next()
                  this.uploadTourStatus({
                    tourName: 'edit_audio_tour',
                    isLoggedIn: this.networkId,
                  })
                },
                classes: 'shepherd-next',
              },
            ],
            id: 'four',
          },
        ])
        if (this.networkId) {
          if (!this.youtubeTourShown.includes('edit_audio_tour')) {
            tour.start()
          }
        } else {
          if (!localStorage.getItem('edit_audio_tour')) {
            tour.start()
          }
        }
      })
    }

    if (!this.selectedEpisodeSettings[this.guid]) {
      this.ytModalSetting({
        guid: this.guid,
        title: this.htmlDecode(this.episode.name),
        description: this.htmlDecode(this.episode.description),
        thumbnailSrc: this.episode.imageUrl ? this.episode.imageUrl : this.currentPodcast.imageUrl,
        startTimeSec: this.episode.startTimeSec || 0,
        endTimeSec: this.episode.endTimeSec || this.episode.durationSeconds,
        category: parseInt(this.episode.category) || 24,
        width: this.episode.width || 1280,
        height: this.episode.height || 720,
        keywords:
          this.episode.youtubeKeywords || ['VIDEO_FREE', 'VIDEO_BASIC', 'VIDEO_PRO'].includes(this.networkType)
            ? ['ai', 'video']
            : ['podcast'],
        episodeKeywords: this.episode.keywords,
        urlChapterMarkers: '',
        chapterMarkers: '',
        customDescription: false,
        includeVisuals: true,
        includeChapters: true,
        privacy: this.episode.privacy || 'public',
        publish: 'Immediately',
        scheduledFor: '',
        isScheduled: false,
        scheduleDate: '',
        scheduleTime: '',
        timezone: '',
        hasTags: false,
        isYTShorts: false,
        shortsCriteria: false,
        hasAudiograms: false,
        burnSubtitle: null,
        isMadeForKids: this.episode.isMadeForKids || null,
        playlistId: this.episode.playlistId,
        videoUrl: this.episode.videoUrl,
        backgroundVideo: true, //this.episode.videoUrl ? true : false,
      })
    }

    this.beginTime = `${new Date(this.selectedEpisodeSettings[this.guid].startTimeSec * 1000)
      .toISOString()
      .substr(11, 8)}`
    this.endTime = new Date(this.selectedEpisodeSettings[this.guid].endTimeSec * 1000).toISOString().substr(11, 8)

    this.width = this.selectedEpisodeSettings[this.guid].width
    this.height = this.selectedEpisodeSettings[this.guid].height
  }

  async created() {
    this.currentAudio = new Audio(this.episode.audioUrl)
  }

  beforeDestroy() {
    this.currentAudio.pause()
    this.$store.commit('setCallTags', false)
  }

  async checkUpload() {
    let payload = {
      networkId: this.networkId,
      params: {
        category: 'TRACK',
        by_guid: `${this.episode.guid}`,
      },
    }
    return await this.checkAudioUpload(payload)
  }

  setShorts() {
    if (['portrait', 'square'].includes(this.active) && this.endTimeSec - this.startTimeSec <= 60) {
      this.ytModalSetting({
        guid: this.guid,
        isYTShorts: true,
        shortsCriteria: true,
      })
    } else {
      this.ytModalSetting({
        guid: this.guid,
        isYTShorts: false,
        shortsCriteria: false,
      })
    }
  }

  @Watch('sliderEndTime')
  sliderEndTimeChanged() {
    if (this.convertHHMMSStoSecs(this.endTime) === this.audio.durationSeconds) this.endTime = this.sliderEndTime
  }

  @Watch('startTimeSec')
  startTimeChanged() {
    if (this.beginTime > this.endTime) {
      this.$store.dispatch('pushNotification', {
        text: 'Clip start cannot be greater than clip end',
        type: 'WARNING',
      })
      this.beginTime = '00:00:00'
      this.ytModalSetting({
        guid: this.guid,
        startTimeSec: 0,
      })
      this.setShorts()
    } else if (this.duration === '0s') {
      this.$store.dispatch('pushNotification', {
        text: 'The duration cannot be 0',
        type: 'WARNING',
      })
      this.beginTime = `${new Date(this.selectedEpisodeSettings[this.guid].startTimeSec * 1000)
        .toISOString()
        .substr(11, 8)}`
      this.ytModalSetting({
        guid: this.guid,
        startTimeSec: 0,
      })
      this.setShorts()
    } else {
      this.ytModalSetting({
        guid: this.guid,
        startTimeSec: this.startTimeSec,
      })
      this.setShorts()
    }
  }
  @Watch('endTimeSec')
  endTimeChanged() {
    if (this.endTime == '00:00:00') {
      this.$store.dispatch('pushNotification', {
        text: 'Clip end cannot be set to 00:00:00',
        type: 'WARNING',
      })
      this.endTime = this.sliderEndTime
      this.ytModalSetting({
        guid: this.guid,
        endTimeSec: this.hmsToSeconds(this.sliderEndTime),
      })
      this.setShorts()
    } else if (this.endTime > this.sliderEndTime && this.getRssEpisodeByGuid(this.guid)) {
      this.$store.dispatch('pushNotification', {
        text: 'Clip end cannot be greater than audio time',
        type: 'WARNING',
      })
      this.endTime = this.sliderEndTime
      this.ytModalSetting({
        guid: this.guid,
        endTimeSec: this.hmsToSeconds(this.sliderEndTime),
      })
      this.setShorts()
    } else if (this.endTime < this.beginTime) {
      this.$store.dispatch('pushNotification', {
        text: 'Clip end cannot be less than clip start',
        type: 'WARNING',
      })
      this.endTime = this.sliderEndTime
      this.ytModalSetting({
        guid: this.guid,
        endTimeSec: this.hmsToSeconds(this.sliderEndTime),
      })
      this.setShorts()
    } else if (this.duration === '0s') {
      this.$store.dispatch('pushNotification', {
        text: 'The duration cannot be 0',
        type: 'WARNING',
      })
      this.endTime = new Date(this.selectedEpisodeSettings[this.guid].endTimeSec * 1000).toISOString().substr(11, 8)
      this.ytModalSetting({
        guid: this.guid,
        endTimeSec: this.endTime,
      })
      this.setShorts()
    } else {
      this.ytModalSetting({
        guid: this.guid,
        endTimeSec: this.endTimeSec,
      })
      this.setShorts()
    }

    this.ytModalSetting({
      guid: this.guid,
      isFullDuration: this.endTime === this.sliderEndTime,
    })
  }
  @Watch('width')
  widthChanged() {
    this.ytModalSetting({
      guid: this.guid,
      width: this.width,
    })
    this.setShorts()
  }
  @Watch('height')
  heightChanged() {
    this.ytModalSetting({
      guid: this.guid,
      height: this.height,
    })
    this.setShorts()
  }

  async handleEnhance() {
    if (this.networkId) {
      if (this.episode.isRss === false) {
        get(this.selectedEpisodeSettings, 'adori_1Ep', null)
          ? this.ytModalId('adori_1Ep')
          : this.ytModalId(this.selectedRssEpisodes[0].guid)
        this.$router.push(`/onboarding?uid=${this.selectedRssEpisodes[0].uid}`)
      } else {
        const res: any = await this.checkUpload()
        this.ytModalId(this.guid)
        this.$router.push(`/onboarding?uid=${res[0].uid}`)
      }
    } else {
      window.open('', 'youtubeAuthHandle', 'width=500,height=715')
      this.$store.commit('setTriggerLogin')
    }
  }

  insertClipStart() {
    this.beginTime = this.sliderValueinHHMMSS
  }
  insertClipEnd() {
    this.endTime = this.sliderValueinHHMMSS
  }
}
