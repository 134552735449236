var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "_baseModal",
          attrs: { hasCustomEvent: true },
          on: {
            closeModal: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [
          _c("template", { slot: "header" }, [
            _c("div", { staticClass: "light-gray f4 fw5" }, [
              _vm._v("Video Preview"),
            ]),
          ]),
          _c("template", { slot: "body" }, [
            _c("div", { staticClass: "w-100" }, [
              _vm.isLoadingSubtitles || _vm.isAudiogramLoading
                ? _c(
                    "div",
                    _vm._l(new Array(1), function (_, index) {
                      return _c("div", {
                        key: index,
                        staticClass:
                          "bg-adori-light-gray bb bw1 b--adori-gray skeleton",
                      })
                    }),
                    0
                  )
                : _c(
                    "div",
                    [
                      _c("SubtitlePreview", {
                        attrs: {
                          backgroundVideo: _vm.backgroundVideo,
                          trackUid: _vm.trackUid,
                          currentTime: _vm.currentTime,
                          isPlaying: _vm.isPlaying,
                          tagsInTrack: _vm.tagsInTrack,
                          coverImage: _vm.coverImage,
                          audiogramImg: _vm.audiogramImg,
                          audiogramVideo: _vm.audiogramVideo,
                          isStepTwo: "True",
                        },
                        on: {
                          "toggle-play": _vm.togglePlay,
                          "scroll-timeline": _vm.scrollTimeline,
                        },
                      }),
                    ],
                    1
                  ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }